import React from 'react';
import './App.css';
require('typeface-poppins')

function App() {
  return (
    <div className="App">
      <div className="background-images">
        <img className="clear-cover" draggable="false" alt= "clearcover" src="./clear-cover.png"></img>
        <img className="white-cover" draggable="false" alt= "whitecover" src="./white-cover.png"></img>
      </div>
      <div className="front">
        <div className="text">
          <div className="title">
            Moves
          </div>
          <div className="tagline">
            What's happening tonight.
          </div>
          <div className="coming-soon">
            Coming soon to the University of Virginia
          </div>
          <a href="https://forms.gle/Wi8g9YhF6JVRhSfs5" className="interested">I'm Interested</a>
        </div>
        <img className="phone-image" draggable="false"  alt= "phone" src="./home.png"></img>
      </div>
      <pre className="footer"><a href="mailto:team@joinmoves.app" className="mailto">Contact Us</a>   |    © {new Date().getFullYear()} Moves   |   <a href="https://docs.google.com/document/d/1vilttVBUXQQQSTpy0ox7JCBfL4WLaVcNdTK69C3MpJw/edit" className="mailto">Terms of Use</a>   |   <a href="https://docs.google.com/document/d/1013PbjYJB6oKXk_L3-jO36nntMOjpQU-vBscteh1Upk/edit" className="mailto">Privacy Policy</a></pre>
    </div>
  );
}

export default App;
